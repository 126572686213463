import styled, { css } from 'styled-components';

export const LogosLine = styled.div`
  display: flex;
  width: 100%;
  max-width: calc(
    224px * ${(props) => props.$logoCount} + ${(props) => props.$logoCount - 1} * 42px
  );
  row-gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 32px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-bottom: 24px;
  }

  &.centered {
    justify-content: center;
    justify-self: center;
  }

  &.withBackground {
    background-color: white;
    border-radius: 130px;
    box-shadow: 25.99px 51.99px 109.96px 0px #63696f1f;
    padding: 23px 32px;
  }

  &.headerContent {
    padding-bottom: 12px;
  }

  &.platform {
    padding: 80px 0 40px 0;

    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      padding-top: 64px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      padding-top: 40px;
    }
  }
  &.noPadding {
    padding: 0px;
    margin-bottom: 0px !important;
  }
`;

export const Logo = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  ${({ $logoCount }) =>
    $logoCount > 2 &&
    css`
      max-width: calc(
        (100% - 42px * (${(props) => props.$logoCount - 1})) / ${(props) => props.$logoCount}
      );
    `};

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    ${({ $logoCount }) =>
      $logoCount > 2 &&
      css`
        max-width: calc(
          (100% - 22px * (${(props) => props.$logoCount - 1})) / ${(props) => props.$logoCount}
        );
      `};
  }

  img {
    height: auto;
    width: auto;
  }

  &.client-logo img {
    max-height: 64px;
    max-width: ${(props) => (props.$logoCount < 3 ? `224px` : `100%`)};
    min-height: auto;
    ${({ $customHeight }) =>
      $customHeight &&
      css`
        max-height: ${(props) => props.$customHeight}px !important;
      `};

    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      max-width: ${(props) => (props.$logoCount < 3 ? `160px` : `100%`)};
      max-height: 48px;
    }
  }

  &.sword-logo img {
    max-height: 28px;

    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      max-height: 20px;
    }
  }

  &.sub-brand-logo img {
    max-height: 48px;

    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      max-height: 32px;
    }
  }

  &.withBackground > img {
    max-height: 24px !important;

    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      max-height: 24px !important;
    }
  }
`;

export const SplitBar = styled.div`
  height: 32px;
  width: 2px;
  background: ${(props) => props.theme.colors.grey.light};
  margin-left: 20px;
  margin-right: 20px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;
